import {BigEmailIcon} from "../assets/BigEmailIcon";
import {Logo} from "../assets/Logo";
import {BigInstaIcon} from "../assets/BigInstaIcon";
import {SmallEmailIcon} from "../assets/SmallEmailIcon";
import {SmallInstaIcon} from "../assets/SmallInstaIcon";
import QR from "../assets/QR.svg";
import Screen4 from "../assets/Screen4.webp";
import LastCoin1 from "../assets/lastcoin1.webp";
import LastCoin2 from "../assets/lastcoin2.webp";
import {Button} from "@nextui-org/react";
import {AppStoreIcon} from "../assets/AppStoreIcon";
import {GooglePlayIcon} from "../assets/GooglePlayIcon";
import { translations } from "../helpers/footer-transations";

const Footer = () => {
    const lang = localStorage.getItem("lang") || "ru";
    const l = translations[lang];

    return (
        <footer id="contact" style={{ background: "#DEE2ED", paddingBottom: 60 }}>
            <div className="container">
                <TopSection l={l} lang={lang} />
                <MissionSection l={l} />
                <InfoSection l={l} />
            </div>
        </footer>
    );
};

const TopSection = ({ l, lang }) => (
    <div className="w-full relative z-40 mt-[40px] flex justify-between gap-[24px] overflow-hidden bg-[#222222] rounded-[24px] min-h-[570px] md:min-h-fit">
        <div className="w-full mb-[40px] p-[24px] text-white flex flex-col justify-between">
            <div className="flex flex-col gap-[16px] relative z-50 w-full">
                <h1 className="text-[40px] text-white font-[600] leading-[56px]">
                    {l.text1.label} {lang === "ru" && <br />} 
                    <span className="text-[#BDFF00]">{l.text2.label}</span> 
                    {lang === "kk" && l.text22.label}
                </h1>
                <p>{l.text3.label}</p>
                <div className="flex w-full justify-between gap-[16px] flex-col md:flex-row">
                    <a href="https://business.sorpa.com/signup">
                        <Button className="text-black bg-[#BDFF00] font-[600] w-full md:max-w-[270px]">
                            {l.text4.label}
                        </Button>
                    </a>
                </div>
            </div>
            <AppDownloadSection l={l} />
            <CoinImages />
        </div>
        <Screenshots />
    </div>
);

const AppDownloadSection = ({ l }) => (
    <div className="flex flex-col gap-[16px] mt-[20px]">
        <p className="w-full">{l.text5.label}</p>
        <div className="flex justify-start w-full gap-[16px] relative z-30">
            {/* <AppStoreIcon /> */}
            <a href="https://play.google.com/store/apps/details?id=com.bekinston.sorpapayv1"><GooglePlayIcon /></a>
        </div>
    </div>
);

const CoinImages = () => (
    <div className="relative w-full flex md:hidden">
        <img src={LastCoin1} className="max-w-[150px] absolute bottom-[70px] left-[160px] z-20" />
        <img src={LastCoin2} className="max-w-[130px] absolute bottom-[-70px] left-0 z-20" />
    </div>
);

const Screenshots = () => (
    <div className="relative w-full hidden md:flex">
        <img src={Screen4} className="max-w-[295px] absolute bottom-0 right-[320px] z-20" />
        <img src={QR} className="max-w-[265px] absolute bottom-[-20px] right-[30px] z-20" />
    </div>
);

const MissionSection = ({ l }) => (
    <div className="container">
        <div className="footer-main-wrapper" style={{ display: "flex", gap: 24, flexDirection: "column" }}>
            <h2 style={{ maxWidth: 400, textAlign: "center", fontSize: 32, fontWeight: 600 }}>{l.text6.label}</h2>
            <p style={{ maxWidth: 400, textAlign: "center" }}>{l.text7.label}</p>
        </div>
    </div>
);

const InfoSection = ({ l }) => (
    <div className="container">
        <div className="footer-info-block" style={{ width: "100%", background: '#FFF', borderRadius: 30, padding: 40 }}>
            <a href="mailto:info@sorpa.com" style={{ display: "flex", alignItems: "center", gap: 16, width: "100%" }}>
                <BigEmailIcon />info@sorpa.com
            </a>
            <div className="header-wrapper" style={{ width: 6, background: "black" }} />
            <a href="https://www.instagram.com/sorpa.app/" target="_blank" className="insta-wrapper" style={{ display: "flex", alignItems: "center", gap: 16, width: "100%" }}>
                <BigInstaIcon />@sorpa.app
            </a>
        </div>
        <FooterEnd l={l} />
    </div>
);

const FooterEnd = ({ l }) => (
    <div className="footer-end">
        <div style={{ display: "flex", flexDirection: "column", gap: 30, width: "100%" }}>
            <Logo />
            <p>{l.text8.label}</p>
            <div style={{ display: "flex", gap: 24 }}>
                <a href="https://apps.apple.com/kz/app/sorpa/id6480465302"><AppStoreIcon /></a>
                <a href="https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"><GooglePlayIcon /></a>
            </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: 30, width: "100%" }}>
            <p className="footer-links" style={{ fontSize: 12 }} onClick={() => setTimeout(() => window.scrollTo(0, 0), 100)}>
                <a href='/conditions'>{l.text9.label}</a> | <a href='/rules'>{l.text10.label}</a> <span className="hidden md:inline">|</span> 
                <a href='/policy' className="w-full md:w-auto"><br className="flex md:hidden" />{l.text11.label}</a>
            </p>
            <div className="footer-links" style={{ display: "flex", gap: 30 }}>
                <a href='mailto:info@sorpa.com'><SmallEmailIcon /></a>
                <a target="_blank" href='https://www.instagram.com/sorpa.app/'><SmallInstaIcon /></a>
            </div>
            <div style={{ display: "flex", gap: 24 }}>
                <p className="footer-links" style={{ fontSize: 12 }}>{l.text12.label}</p>
            </div>
        </div>
    </div>
);

export default Footer;