import Screen1 from "../../assets/Screen1.webp";
import Screen2 from "../../assets/Screen2.webp";
import Screen3 from "../../assets/Screen3.webp";
import translations from "../../helpers/main-body-translations";

const AboutSection = ({ lang }) => {
    const l = translations[lang] || translations["ru"];

    return (
        <section id="about" className="container">
            <div className="container py-[40px] md:py-[100px] gap-[24px] md:gap-[36px]">
                <div className="flex items-center justify-start w-full gap-[12px]">
                    <h2 className="text-[28px]">{l.text20.label}</h2>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-start w-full gap-[24px]">
                    <div className="bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]">
                        <h3 className="text-[24px] font-[600] text-center">{l.text21.label}</h3>
                        <p className="text-center">{l.text22.label}</p>
                        <img src={Screen1} className="absolute bottom-0 right-0" />
                    </div>
                    <div className="bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]">
                        <h3 className="text-[24px] font-[600] text-center">{l.text23.label}</h3>
                        <p className="text-center">{l.text24.label}</p>
                        <img src={Screen2} className="absolute bottom-0 right-0" />
                    </div>
                    <div className="bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]">
                        <h3 className="text-[24px] font-[600] text-center">{l.text25.label}</h3>
                        <p className="text-center">{l.text26.label}</p>
                        <img src={Screen3} className="absolute bottom-0 right-0" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
