import {SorpaWhiteIcon} from "../../assets/SorpaWhiteIcon";
import {SorpaSmall} from "../../assets/SorpaSmall";
import {SmallestSorpa} from "../../assets/SmallestSorpa";
import BigCard from "../../assets/BigCard.webp";
import Keks from "../../assets/keks.webp";
import Eggs from "../../assets/Eggs.webp";
import Fist from "../../assets/fist.webp";
import MobFist from "../../assets/mobilefist.webp";
import Hand from "../../assets/hand.webp";
import MobHand from "../../assets/MobHand.webp";
import translations from "../../helpers/main-body-translations";
import {AppStoreIcon} from "../../assets/AppStoreIcon";
import {GooglePlayIcon} from "../../assets/GooglePlayIcon";

const DobavkaSection = ({ lang, amount, modifiedAmount, addNumber, handleChange }) => {
    const l = translations[lang] || translations["ru"];

    return (
        <section id="dobavka" className="w-full bg-white">
            <div className="container py-[40px] md:py-[100px] gap-[24px]">
                <div className="flex items-center justify-start w-full gap-[12px]">
                    <h2 className="text-[28px]">{l.text7.label}</h2>
                    <SorpaWhiteIcon />
                </div>
                <p className="w-full">{l.text8.label}</p>
                <div className="w-full flex gap-[16px]">
                    <img src={BigCard} className="max-w-[384px] w-full hidden xl:flex" />
                    <div className="flex flex-col gap-[8px] relative">
                        {/* Cards */}
                        <div className="flex flex-col md:flex-row gap-[8px] relative">
                            <div className="bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col">
                                <h3 className="text-[24px] font-[500] relative z-40">{l.text9.label}</h3>
                                <p className="text-[14px] relative z-40">{l.text10.label}</p>
                                <img src={Keks} className="absolute bottom-0 right-0 w-[120px] md:w-[180px] z-30" />
                            </div>
                            <div className="bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col">
                                <h3 className="text-[24px] font-[500] relative z-40">{l.text11.label}</h3>
                                <p className="text-[14px] relative z-40">{l.text12.label}</p>
                                <img src={Eggs} className="absolute bottom-[-10px] md:bottom-0 left-[12px] w-[180px] z-30" />
                                <img src={Fist} className="absolute bottom-0 right-0 top-[130px] z-30 w-[94px] hidden md:flex" />
                                <img src={MobFist} className="absolute right-[20px] bottom-0 z-30 w-[180px] flex md:hidden" />
                            </div>
                            <div className="bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col">
                                <h3 className="text-[24px] font-[500] relative z-40">{l.text13.label}</h3>
                                <p className="text-[14px] relative z-40">{l.text14.label}</p>
                                <img src={Hand} className="absolute bottom-0 left-0 w-[110px] z-30 hidden md:flex" />
                                <img src={MobHand} className="absolute top-0 right-0 w-[110px] z-30 flex md:hidden" />
                            </div>
                        </div>
                        {/* Balance update section */}
                        <div className="bg-[#DEE2ED] rounded-[24px] p-[24px] w-full relative overflow-hidden gap-[12px] flex flex-col">
                            <h3 className="text-[24px] font-[500] relative z-40">{l.text15.label}</h3>
                            <div className="flex flex-col md:flex-row gap-[8px]">
                                <div className="flex w-full flex-col bg-white gap-[4px] rounded-[8px] p-[12px]">
                                    <h4 className="text-[#888888]">{l.text16.label}</h4>
                                    <input
                                        className="focus-visible:ring-offset-0 focus-visible:ring-0 text-[24px] caret-[#4469FF]"
                                        type="text"
                                        value={amount}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] p-[12px]">
                                    <h4 className="text-[white]">{l.text17.label}</h4>
                                    <p className="text-[24px] text-[white] flex items-center gap-[8px] font-[600]">
                                        {modifiedAmount}
                                        <span className="text-[#4469FF]"><SorpaSmall /></span>
                                    </p>
                                    <p className="text-[14px] text-[white] flex items-center gap-[4px] font-[600]">
                                        {amount}
                                        <span className="text-[#4469FF]"><SmallestSorpa /></span>
                                        <span className="text-[#BDFF00]">+{addNumber} {l.text18.label}</span>
                                    </p>
                                </div>
                            </div>
                            {/* Percentage Blocks */}
                            <div className="w-full flex flex-col md:flex-row gap-[8px]">
                                <div className="flex w-full gap-[8px]">
                                    <div className="flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]">
                                        <h4 className="text-[white] text-center">{lang === "ru" && "От"} 1 000{lang === "kk" && "-нан"}</h4>
                                        <p className="text-[#BDFF00] font-[800] text-center w-full">+5%</p>
                                    </div>
                                    <div className="flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]">
                                        <h4 className="text-[white] text-center">{lang === "ru" && "От"} 10 000{lang === "kk" && "-нан"}</h4>
                                        <p className="text-[#BDFF00] font-[800] text-center w-full">+10%</p>
                                    </div>
                                </div>
                                <div className="flex w-full gap-[8px]">
                                    <div className="flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]">
                                        <h4 className="text-[white] text-center">{lang === "ru" && "От"} 50 000{lang === "kk" && "-нан"}</h4>
                                        <p className="text-[#BDFF00] font-[800] text-center w-full">+15%</p>
                                    </div>
                                    <div className="flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]">
                                        <h4 className="text-[white] text-center">{lang === "ru" && "От"} 100 000{lang === "kk" && "-нан"}</h4>
                                        <p className="text-[#BDFF00] font-[800] text-center w-full">+20%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full gap-[12px]">
                    <p>{l.text6.label}</p>
                    <div className="flex justify-end md:justify-start gap-[16px] relative z-30">
                        <a href="https://apps.apple.com/kz/app/sorpa/id6480465302"><AppStoreIcon /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"><GooglePlayIcon /></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DobavkaSection;