import Img1 from "../../assets/resto.webp";
import Img2 from "../../assets/face.webp";
import Img3 from "../../assets/clinik.webp";
import Img4 from "../../assets/sport.webp";
import Img5 from "../../assets/fun.webp";
import Img6 from "../../assets/clothes.webp";
import Img7 from "../../assets/clean.webp";
import Img8 from "../../assets/crm.webp";
import Img9 from "../../assets/flower.webp";
import Img10 from "../../assets/bal.webp";
import Img11 from "../../assets/travel.webp";
import { AppStoreIcon } from "../../assets/AppStoreIcon";
import { GooglePlayIcon } from "../../assets/GooglePlayIcon";
import translations from "../../helpers/main-body-translations";

const PartnersSection = ({ lang }) => {
    const l = translations[lang] || translations["ru"];

    return (
        <section id="partners" className="w-full bg-white">
            <div className="container py-[40px] md:py-[100px] gap-[24px]">
                <div className="flex items-center justify-start w-full gap-[12px]">
                    <h2 className="text-[28px]">{l.text27.label}</h2>
                </div>
                <div className="flex flex-col w-full gap-[16px] md:gap-[4px]">
                    <div className="w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]">
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text28.label}</h4>
                            <img src={Img1} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full md:max-w-[324px] rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text29.label}</h4>
                            <img src={Img2} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text30.label}</h4>
                            <img src={Img3} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]">
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text31.label}</h4>
                            <img src={Img4} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text32.label}</h4>
                            <img src={Img5} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text33.label}</h4>
                            <img src={Img6} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]">
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text34.label}</h4>
                            <img src={Img7} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full md:max-w-[324px] rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text35.label}</h4>
                            <img src={Img8} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text36.label}</h4>
                            <img src={Img9} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]">
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text37.label}</h4>
                            <img src={Img10} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                        <div className="h-[200px] md:h-[300px] w-full rounded-[24px] relative">
                            <h4 className="relative z-40 text-white p-[24px] text-[28px]">{l.text38.label}</h4>
                            <img src={Img11} className="w-full h-full absolute z-30 top-0 object-cover rounded-[24px]" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-[16px]">
                    <p className="w-full text-center text-[28px]">{l.text39.label}</p>
                    <div className="flex justify-center gap-[16px] relative z-30">
                        <a href="https://apps.apple.com/kz/app/sorpa/id6480465302"><AppStoreIcon /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"><GooglePlayIcon /></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnersSection;
