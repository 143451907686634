import { useState } from "react";
import { Button } from "@nextui-org/react";
import HeroSection from "./HeroSection";
import DobavkaSection from "./DobavkaSection";
import AboutSection from "./AboutSection";
import PartnersSection from "./PartnersSection";
import translations from "../../helpers/main-body-translations";

export const Main = () => {
  const [amount, setAmount] = useState("1 000 000");
  const [modifiedAmount, setModifiedAmount] = useState("1 200 000");
  const [addNumber, setAddNumber] = useState("200 000");
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const numericValue = inputValue.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('ru-RU').format(numericValue);
    setAmount(formattedValue);

    let mValue = 0;
    if (numericValue >= 1000 && numericValue < 10000) {
      mValue = numericValue * 1.05;
      setError(false);
    } else if (numericValue >= 10000 && numericValue < 50000) {
      mValue = numericValue * 1.1;
      setError(false);
    } else if (numericValue >= 50000 && numericValue < 100000) {
      mValue = numericValue * 1.15;
      setError(false);
    } else if (numericValue >= 100000) {
      mValue = numericValue * 1.2;
      setError(false);
    }
    if (numericValue >= 1000) {
      const addNumberValue = mValue - numericValue;
      const formattedM = new Intl.NumberFormat('ru-RU').format(mValue);
      const formattedAM = new Intl.NumberFormat('ru-RU').format(addNumberValue);
      setModifiedAmount(formattedM);
      setAddNumber(formattedAM);
    } else {
      setError(true);
      setModifiedAmount("0");
      setAddNumber("0");
    }
  };

  const lang = localStorage.getItem("lang");
    const l = translations[lang] || translations["ru"];

    return (
        <div>
            <HeroSection lang={lang} l={l} />
            <DobavkaSection
                lang={lang}
                l={l}
                amount={amount}
                modifiedAmount={modifiedAmount}
                addNumber={addNumber}
                handleChange={handleChange}
                error={error}
            />
            <AboutSection lang={lang} l={l} />
            <PartnersSection lang={lang} l={l} />
        </div>
    );
};