import { Button } from "@nextui-org/react";
import { AppStoreIcon } from "../../assets/AppStoreIcon";
import { GooglePlayIcon } from "../../assets/GooglePlayIcon";
import BigSorpaCoin from "../../assets/BigSorpaCoin.webp";
import SmallSorpaCoin from "../../assets/SmallSorpaCoin.webp";
import SorpaScreen from "../../assets/SorpaScreen.webp";
import translations from "../../helpers/main-body-translations";

const HeroSection = ({ lang }) => {
    const l = translations[lang] || translations["ru"]; 

    return (
        <section className="container" id="">
            <div className="w-full relative z-40">
                <div className="hero-back w-full mb-[40px] rounded-[24px] p-[24px] text-white flex flex-col min-h-[540px] justify-between relative z-50 md:h-[690px] overflow-hidden">
                    <img src={BigSorpaCoin} className="absolute z-30 top-[-64px] md:top-[-14px] right-[-162px] md:right-[200px] w-[360px] md:w-[451px] object-cover" />
                    <img src={SmallSorpaCoin} className="absolute z-30 top-[483px] left-[-37px] md:left-[270px] w-[160px] md:w-[208px]" />
                    <img src={SorpaScreen} className="absolute z-30 top-[180px] md:right-[20px] lg:right-[80px] w-[337px] hidden md:flex" />
                    <div className="flex flex-col gap-[16px] mt-[60px] relative z-50">
                        <h1 className={`text-[40px] text-[${l.text2.color}] font-[600] leading-[54px]`}>
                            <span className={`text-[${l.text1.color}]`}>{l.text1.label}</span> 
                            {lang === "kk" && <br />} 
                            {l.text2.label}
                        </h1>
                        <p className={`max-w-[500px] text-[${l.text3.color}]`}>{l.text3.label}</p>
                        <div className="flex w-full max-w-[500px] justify-between gap-[16px] flex-col md:flex-row">
                            <a href="/#about">
                                <Button fullWidth={true} className={`text-[${l.text4.color}] bg-[#BDFF00] font-[600]`}>{l.text4.label}</Button>
                            </a>
                            <a href="/#partners">
                                <Button fullWidth={true} className={`text-[${l.text5.color}] bg-[#BDFF00] font-[600] w-full`}>{l.text5.label}</Button>
                            </a>
                        </div>
                    </div>
                    <div className="mt-[80px] lg:mb-[40px] flex flex-col gap-[16px]">
                        <p className={`w-full text-[${l.text6.color}] text-right md:text-left`}>{l.text6.label}</p>
                        <div className="flex justify-end md:justify-start gap-[16px] relative z-30">
                            <a href="https://apps.apple.com/kz/app/sorpa/id6480465302"><AppStoreIcon /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"><GooglePlayIcon /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
