import '../App.css';
import { SorpaIcon } from "../assets/SorpaIcon";
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button } from "@nextui-org/react";
import { BurgerMenu } from "../assets/BurgerMenu";
import { Anchor } from "../assets/Anchor";

const translations = {
  ru: {
    text1: { label: "Добавка", color: "white" },
    text2: { label: "Партнеры", color: "white" },
    text3: { label: "О проекте", color: "white" },
    text4: { label: "Контакты", color: "white" },
    text5: { label: "Скачать", color: "white" },
  },
  kk: {
    text1: { label: "Қосымша", color: "white" },
    text2: { label: "Серіктестеріміз", color: "white" },
    text3: { label: "Жоба туралы", color: "white" },
    text4: { label: "Байланысу", color: "white" },
    text5: { label: "Жүктеу", color: "white" },
  },
};

const LanguageDropdown = ({ lang, onChangeLanguage }) => (
  <Dropdown
    shadow="sm"
    className="bg-transparent"
    classNames={{
      content: "bg-[#242424] mt-[10px]",
    }}
  >
    <DropdownTrigger>
      <button className="flex items-center gap-[4px] text-[14px]">
        {lang === "ru" ? "Русский" : "Қазақша"} <Anchor />
      </button>
    </DropdownTrigger>
    <DropdownMenu aria-label="Static Actions"
      itemClasses={{
        base: [
          "data-[hover=true]:text-[white]",
          "data-[hover=true]:bg-[transparent]",
        ],
      }}
    >
      <DropdownItem key="ru" className="text-white">
        <button onClick={() => onChangeLanguage("ru")}>Русский</button>
      </DropdownItem>
      <DropdownItem key="kk" className="text-white">
        <button onClick={() => onChangeLanguage("kk")}>Қазақша</button>
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>
);

const NavigationLinks = ({ lang, links }) => (
  <div className="hidden md:flex text-white items-center gap-[24px]">
    {links.map((link, index) => (
      <a key={index} className="text-white text-[14px]" href={link.href}>
        {link.label}
      </a>
    ))}
    <a href={window.location.href.includes("kz") ? "/kz/download" : "/download"}>
      <Button className="rounded-full bg-[#BDFF00] font-[600]">
        {translations[lang]?.text5?.label}
      </Button>
    </a>
  </div>
);

const MobileMenu = ({ lang, links }) => (
  <div className="flex md:hidden">
    <Dropdown
      shadow="sm"
      className="bg-transparent"
      classNames={{
        content: "bg-[#242424] mt-[10px]",
      }}
    >
      <DropdownTrigger>
        <button><BurgerMenu /></button>
      </DropdownTrigger>
      <DropdownMenu aria-label="Static Actions"
        itemClasses={{
          base: [
            "data-[hover=true]:text-foreground",
            "data-[hover=true]:bg-[transparent]",
          ],
        }}
      >
        <DropdownItem className="text-white">
          <a href="https://business.sorpa.com" className="text-white text-[18px]">sorpa pay</a>
        </DropdownItem>
        {links.map((link, index) => (
          <DropdownItem key={index} className="text-white">
            <a className="text-white text-[14px]" href={link.href}>
              {link.label}
            </a>
          </DropdownItem>
        ))}
        <DropdownItem className="text-white hover:bg-transparent">
          <a className="text-white text-[14px]" href="/download">
            <Button className="rounded-full bg-[#BDFF00] font-[600] w-full">
              {translations[lang]?.text5?.label}
            </Button>
          </a>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </div>
);

const Header = () => {
  const lang = localStorage.getItem("lang") || "ru";

  const handleLanguageChange = (language) => {
    localStorage.setItem("lang", language);
    window.location.reload();
  };

  const links = [
    { label: translations[lang]?.text1?.label, href: '#dobavka' },
    { label: translations[lang]?.text2?.label, href: '#partners' },
    { label: translations[lang]?.text3?.label, href: '#about' },
    { label: translations[lang]?.text4?.label, href: '#contact' },
  ];

  return (
    <div className="w-full p-[12px] sticky top-0 z-50">
      <div className="bg-[#242424] w-full max-w-[1200px] m-auto rounded-[24px] flex items-center justify-between p-[12px] md:px-[24px] py-[12px] border-[#DEE2ED] border-[1px]">
        <div className="flex text-white items-center gap-[12px]">
          <a href="/"><SorpaIcon /></a>
          <a href="/"><p className="text-[#BDFF00]">sorpa</p></a>
          <a href="https://business.sorpa.com" className="text-white hidden md:flex">sorpa pay</a>
          <LanguageDropdown lang={lang} onChangeLanguage={handleLanguageChange} />
        </div>
        <NavigationLinks lang={lang} links={links} />
        <MobileMenu lang={lang} links={links} />
      </div>
    </div>
  );
};

export default Header;
